import api from "@/api";

const application = {
  list(params) {
    return api.get(`/application`, {
      params: params,
    });
  },
  create(data) {
    return api.post(`/application`, data);
  },
  get(pk) {
    return api.get(`/application/${pk}`);
  },
  update(pk, data) {
    return api.patch(`/application/${pk}`, data);
  },
  changeState(pk, data) {
    return api.patch(`/application/${pk}/state`, data);
  },
  destroy(pk) {
    return api.delete(`/application/${pk}`);
  },
};

export default application;
