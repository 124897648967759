<template>
  <div id="application" v-loading="loading">
    <div class="cm-content">
      <el-table :data="data">
        <el-table-column label="应用图标">
          <template slot-scope="scope">
            <el-image
              style="width: 50px; height: 50px"
              :src="scope.row.img_url"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="应用名称" prop="name"></el-table-column>
        <el-table-column label="应用描述" prop="description"></el-table-column>
        <el-table-column label="入口地址" prop="entry_url"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ scope.row.state | formatApplicationState }}
          </template>
        </el-table-column>
        <el-table-column label="时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
import application from "@/api/application";

export default {
  name: "application",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
    },
    handleList(params) {
      this.loading = true;
      application.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "application",
          query: params,
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "application") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus">
#application {
  display: flex;
  height: 100%;
}
</style>
